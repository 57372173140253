import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import "react-quill/dist/quill.snow.css";

import TextInput from "../../forms/TextInput";
import BooleanSwitchInput from '../../forms/BooleanSwitchInput';
import RangeInput from '../../forms/RangeInput';

function Add({ refreshList, addNotification }) {
 
  const [skillName, setSkillName] = useState("");
  const [isSoft, setIsSoft] = useState("");
  const [experience, setExperience] = useState("");

  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "skills";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
        if (doc.exists && isMounted) {

        }
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };
    fetchDropdownValues();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const newItem = {
          skillName,
          isSoft,
          experience,
        isOn: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const newItemRef = await db.collection(firestoreCollection).add(newItem);
      if (refreshList) {
        refreshList();
      }
      setSkillName("");
      setIsSoft(false);
      setExperience("");

      addNotification("New Item added successfully", "success");

      return newItemRef.id;
    } catch (error) {
      console.error("Error during form submission:", error);
      addNotification(
        "Error during form submission. Please try again.",
        "danger"
      );
    } 
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="label tabs" onSubmit={handleSubmit}>
      <Nav tabs justified >
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
          <Col > 
          <TextInput
            label="Skill Name"
            value={skillName}
            onChange={(e) => setSkillName(e.target.value)}
            placeholder="e.g Game Developer"
          />
          <BooleanSwitchInput 
          label="Soft Skill"
          value={isSoft}
          onChange={setIsSoft} />
          
          <RangeInput 
          label="Experience"
          min="1"
          max="100"
          value={experience}
          onChange={(e) => setExperience(e.target.value)} 
          />

           </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-1 primary"
        // color="primary"
        type="submit"
      >
        Add
      </Button>
    </Form>
  );
}

export default Add;
