import { db, storage, firebase } from "../../firebase"; 
import { deleteDoc, doc } from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";

export const fetchItems = async (firebaseCollection) => {
    try {
        const itemsRef = db.collection(firebaseCollection).orderBy("createdAt");
        const itemsSnapshot = await itemsRef.get();

        return itemsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
        console.error(`Error fetching items from ${firebaseCollection}:`, error);
        throw error; // Re-throwing the error to handle it in the calling function
    }
};

export const fetchDropdowns = async (firebaseCollection) => {
    try {
        const dropdownRef = db.collection("dropdowns").doc(firebaseCollection);
        const dropdownDoc = await dropdownRef.get();

        if (dropdownDoc.exists) {
            const dropdownData = dropdownDoc.data();

            // Dynamically create the selectedFilters state
            const dynamicSelectedFilters = Object.keys(dropdownData).reduce((acc, key) => {
                acc[key] = [];
                return acc;
            }, {});

            return { dropdownData, dynamicSelectedFilters };
        }
        return null;
    } catch (error) {
        console.error(`Error fetching dropdown values from ${firebaseCollection}:`, error);
        throw error; // Re-throwing the error to handle it in the calling function
    }
};

export const deleteMediaFromStorage = async (mediaUrls) => {
    if (mediaUrls && Array.isArray(mediaUrls)) {
        for (let url of mediaUrls) {
            try {
                await storage.refFromURL(url).delete(); // Delete using the URL
         
            } catch (error) {
                console.error("Error deleting media from storage:", error);
               
                throw error; // Re-throwing the error to handle it in the calling function
            }
        }
    }
};

export const deleteItemFromCollection = async (collectionName, itemId) => {
    try {
        await db.collection(collectionName).doc(itemId).delete();
    } catch (error) {
        console.error(`Error deleting item from ${collectionName}:`, error);
        throw error; // Re-throwing the error to handle it in the calling function
    }
};

export const cleanupUnsubmittedMedia = async (addNotification) => {
    try {
      const tempCollection = db.collection("temp");
      const docRef = tempCollection.doc("unsubmittedMediaUrls");
      const lastCleanupRef = tempCollection.doc("lastCleanupTimestamp");

      const currentDate = new Date();
      const currentDay = currentDate.toISOString().split("T")[0]; // Extracts the "YYYY-MM-DD" format

      // Fetch the last cleanup date
      const lastCleanupDoc = await lastCleanupRef.get();
      if (lastCleanupDoc.exists && lastCleanupDoc.data().date === currentDay) {
        // Cleanup has already been done for today
        return;
      }

      const doc = await docRef.get();
      if (doc.exists && doc.data().urls) {
        const urls = doc.data().urls;
        let deletedCount = 0;

        for (let url of urls) {
          try {
            const fileRef = storage.refFromURL(url);
            await fileRef.delete();
            deletedCount++;
          } catch (error) {
            console.error("Error deleting file from URL:", url, error);
          }
        }

        await docRef.update({ urls: [] });

        // Update the last cleanup date
        await lastCleanupRef.set({ date: currentDay });

        if (deletedCount > 0) {
          addNotification(
            `${deletedCount} trash media files are cleaned from storage. These files occurred due to unsuccessful submissions.`,
            "success"
          );
        }
      }
    } catch (error) {
      console.error("Error during cleanup:", error);
      addNotification(
        "Error during media cleanup. Please check the logs.",
        "danger"
      );
    }
  };

  
  export const deleteItem = async (
    selectedItem,
    items,
    setItems,
    setSelectedItem,
    setShowDeleteConfirmation,
    addNotification,
    firestoreCollection
  ) => {
    const { id, LogoUrl } = selectedItem;
  
    try {
      // Attempt to delete the logo from Firebase Storage if the LogoUrl exists
      if (LogoUrl && LogoUrl[0]) {
        const storageRef = ref(storage, LogoUrl[0]);
        await deleteObject(storageRef)
          .then(() => {
            console.log("File deleted successfully");
          })
          .catch((error) => {
            // If the file is not found, log the error but proceed with deletion
            if (error.code === "storage/object-not-found") {
              console.warn("File not found in storage, proceeding with item deletion");
            } else {
              throw error; // Rethrow other errors
            }
          });
      }
  
      // Proceed with deleting the item from Firestore
      await deleteDoc(doc(db, firestoreCollection, id));
  
      // Update local state
      setItems(items.filter((item) => item.id !== id));
      setSelectedItem(null);
      setShowDeleteConfirmation(false);
  
      // Notify the user
      addNotification("Item deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting item:", error);
      addNotification("Error deleting item. Please try again.", "danger");
    }
  };
  
  
  export const toggleItemStatus = async (
    itemId,
    newStatus,
    items,
    setItems,
    addNotification,
    firestoreCollection
  ) => {
    try {
      // Update the 'isOn' field in the database for the given itemId with newStatus
      db.collection(firestoreCollection)
        .doc(itemId)
        .update({
          isOn: newStatus,
        })
        .then(() => {
          // Fetch the updated item
          db.collection(firestoreCollection)
            .doc(itemId)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const updatedItem = { ...doc.data(), id: doc.id };
  
                // Find the index of the updated item in the items state
                const index = items.findIndex((item) => item.id === itemId);
  
                // Replace the old item data with the new item data
                if (index !== -1) {
                  const updatedItems = [...items];
                  updatedItems[index] = updatedItem;
                  setItems(updatedItems);
                  addNotification("New state is applied successfully", "success");
                }
              }
            });
        });
    } catch (error) {
      console.error("Error updating item status:", error);
      addNotification("Error updating item status. Please try again.", "danger");
    }
  };