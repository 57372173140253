import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';

function Notification({ message, type, onClose, duration = 7000 }) {
  useEffect(() => {
    // Set a timer to automatically close the notification after the specified duration
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer); // Clear the timer if the component is unmounted before the timeout
  }, [onClose, duration]);

  return (
    <Alert color={type} isOpen={true} toggle={onClose}>
      {message}
    </Alert>
  );
}

export default Notification;
