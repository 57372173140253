// RichEditor.js
import React from 'react';
import { FormGroup, Label } from "reactstrap";
import ReactQuill from "react-quill";


function RichEditor({ label, value, onChange }) {
  return (
    <FormGroup>
      <Label for="description">{label}</Label>
      <ReactQuill className='rich-editor' value={value} onChange={onChange} />
    </FormGroup>
  );
}

export default RichEditor;
