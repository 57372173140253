import React, { useState, useEffect } from "react";
import {
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faPlus,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../../../firebase.js";
import Add from "./Add.js";
import Update from "./Update.js";
import Settings from "./Settings.js";
import {
  fetchItems,
  fetchDropdowns,
  cleanupUnsubmittedMedia,
  deleteItem,
  toggleItemStatus,
} from "../../utils/firebaseUtils.js";
import Filters from "../../Filters.js";

function GenericModal({ isOpen, toggle, title, children }) {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="footer font-2" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody className="modal-container">{children}</ModalBody>
    </Modal>
  );
}

function Skills({ addNotification }) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [filters, setFilters] = useState({ status: [] });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const firestoreCollection = "skills";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemsData = await fetchItems(firestoreCollection);
        setItems(itemsData);

        const dropdownResult = await fetchDropdowns(firestoreCollection);
        if (dropdownResult) {
          setFilters(dropdownResult.dropdownData);
          setSelectedFilters(dropdownResult.dynamicSelectedFilters);
        }

        cleanupUnsubmittedMedia(addNotification);
      } catch (error) {
        console.error("Error fetching data:", error);
        addNotification(
          "Error fetching data. Please refresh the page.",
          "danger"
        );
      }
    };

    fetchData();
  }, []);

  const [activeModal, setActiveModal] = useState(null);
  const openModal = (modalName) => setActiveModal(modalName);
  const closeModal = () => setActiveModal(null);

  const handleSelectItemAndOpenUpdateModal = (item) => {
    setSelectedItem(item);
    openModal("UPDATE");
  };

  const handleSelectItemAndOpenDeleteModal = (item) => {
    setSelectedItem(item);
    openModal("DELETE_CONFIRMATION");
  };

  const refreshDropdowns = async () => {
    const dropdownResult = await fetchDropdowns(firestoreCollection);
    if (dropdownResult) {
      setFilters(dropdownResult.dropdownData);
      setSelectedFilters(dropdownResult.dynamicSelectedFilters);
    }
  };

  const handleDeleteItem = () => {
    deleteItem(
      selectedItem,
      items,
      setItems,
      setSelectedItem,
      setShowDeleteConfirmation,
      addNotification,
      firestoreCollection
    );
  };

  const handleToggleItemStatus = (itemId, newStatus) => {
    toggleItemStatus(
      itemId,
      newStatus,
      items,
      setItems,
      addNotification,
      firestoreCollection
    );
  };

  const refreshItemsList = async (newItemId) => {
    if (newItemId) {
      // Fetch only the new item
      const newItemDoc = await db
        .collection(firestoreCollection)
        .doc(newItemId)
        .get();
      if (newItemDoc.exists) {
        const newItem = { ...newItemDoc.data(), id: newItemDoc.id };
        setItems((prevItems) => [...prevItems, newItem]);
      }
    } else {
      // Fetch all items
      const itemsData = await fetchItems(firestoreCollection);
      setItems(itemsData);
    }
  };

  function ItemSwitch({ isOn, onToggle }) {
    return (
      <FormGroup switch inline>
        <Input type="switch" checked={isOn} onChange={onToggle} />
      </FormGroup>
    );
  }

  const applyLocalFilters = () => {
    let filteredItems = items;

    Object.keys(selectedFilters).forEach((category) => {
      if (selectedFilters[category].length > 0) {
        filteredItems = filteredItems.filter((item) =>
          selectedFilters[category].every(
            (filter) => item[category] && item[category].includes(filter)
          )
        );
      }
    });

    return filteredItems;
  };

  return (
    <div>
      <div className="p-3 manager-header">
        <h1 className="title">Edit Personal Skill Details</h1>
        <div className="mt-2 button-container">
          <Button className="primary" onClick={() => openModal("ADD")}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
          </Button>
          <Button className="primary m-2" onClick={() => openModal("SETTINGS")}>
            <FontAwesomeIcon icon={faCog} /> Settings
          </Button>
        </div>
        <br />
        {/* Filters */}
        <Filters
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          items={items}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />

        <hr className="font-2" />
      </div>

      <div className="list-group mt-4 list">
        <h4>My Skill Entries</h4>
        <hr />
        {/* listing items */}
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: "2vh" }}
        >
          {applyLocalFilters().map((item) => (
            <div
              key={item.id}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {/* Display the logo */}
              <div style={{ marginRight: "10px" }}></div>

              {/* Display the item details */}
              <ListGroupItem
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 5,
                }}
                className="list-item item"
              >
                <span style={{ marginLeft: "20px" }} className="font-2">
                  {item.skillName} with {item.experience} experience
                </span>

                <span>
                  <ItemSwitch
                    style={{
                      cursor: "pointer",
                      height: "25px",
                    }}
                    isOn={item.isOn}
                    onToggle={() => handleToggleItemStatus(item.id, !item.isOn)}
                  />
                </span>
              </ListGroupItem>

              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: 0,
                }}
                className="action-icon"
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className="mr-2"
                  style={{
                    cursor: "pointer",
                    height: "25px",
                    marginRight: "10px",
                  }}
                  onClick={() => handleSelectItemAndOpenDeleteModal(item)}
                />
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{
                    cursor: "pointer",
                    height: "25px",
                    marginRight: "10px",
                  }}
                  className="action-icon"
                  onClick={() => handleSelectItemAndOpenUpdateModal(item)}
                />
              </div>
            </div>
          ))}
        </div>

        <GenericModal
          isOpen={activeModal === "ADD"}
          toggle={closeModal}
          title="Add"
        >
          <Add
            addNotification={addNotification}
            refreshList={refreshItemsList}
          />
        </GenericModal>

        <GenericModal
          isOpen={activeModal === "UPDATE"}
          toggle={closeModal}
          title="Update"
        >
          {selectedItem && (
            <Update
              refreshList={refreshItemsList}
              addNotification={addNotification}
              itemData={selectedItem}
              onUpdateSuccess={closeModal}
              firestoreCollection={firestoreCollection}
            />
          )}
        </GenericModal>

        <GenericModal
          isOpen={activeModal === "DELETE_CONFIRMATION"}
          toggle={closeModal}
          title="Confirm Deletion"
        >
          <div>
            Are you sure you want to delete this item?
            <ModalFooter>
              <Button color="danger" onClick={handleDeleteItem}>
                Delete
              </Button>
              <Button color="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </GenericModal>

        <GenericModal
          isOpen={activeModal === "SETTINGS"}
          toggle={closeModal}
          title="Settings"
        >
          <Settings
            refreshDropdowns={refreshDropdowns}
            addNotification={addNotification}
            firestoreCollection={firestoreCollection}
          />
        </GenericModal>
      </div>
    </div>
  );
}

export default Skills;
