import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';

function Stats() {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      {/* Tabs Navigation */}
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggleTab("1")}
          >
            Game Developer
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggleTab("2")}
          >
            Game Designer
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggleTab("3")}
          >
            Web Developer
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "4" })}
            onClick={() => toggleTab("4")}
          >
            RASITGR
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "5" })}
            onClick={() => toggleTab("5")}
          >
            LUPI
          </NavLink>
        </NavItem>
      </Nav>

      {/* Tabs Content */}
      <TabContent activeTab={activeTab} className="mt-3">
        <TabPane tabId="1">
          {activeTab === "1" && (
            <div className="lookerstudio-container embed-responsive embed-responsive-16by9">
              <iframe
                src="https://lookerstudio.google.com/embed/reporting/GAME_DEVELOPER_LOOKERSTUDIO_URL"
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                frameBorder="0"
                allowFullScreen
                className="lookerstudio-embed embed-responsive-item"
              ></iframe>
            </div>
          )}
        </TabPane>

        <TabPane tabId="2">
          {activeTab === "2" && (
            <div className="lookerstudio-container embed-responsive embed-responsive-16by9">
              <iframe
                src="https://lookerstudio.google.com/embed/reporting/GAME_DESIGNER_LOOKERSTUDIO_URL"
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                frameBorder="0"
                allowFullScreen
                className="lookerstudio-embed embed-responsive-item"
              ></iframe>
            </div>
          )}
        </TabPane>

        <TabPane tabId="3">
          {activeTab === "3" && (
            <div className="lookerstudio-container embed-responsive embed-responsive-16by9">
              <iframe
                src="https://lookerstudio.google.com/embed/reporting/WEB_DEVELOPER_LOOKERSTUDIO_URL"
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                frameBorder="0"
                allowFullScreen
                className="lookerstudio-embed embed-responsive-item"
              ></iframe>
            </div>
          )}
        </TabPane>

        <TabPane tabId="4">
          {activeTab === "4" && (
            <div className="lookerstudio-container embed-responsive embed-responsive-16by9">
              <iframe
               src="https://lookerstudio.google.com/embed/reporting/2f8d854e-560c-438e-8c3a-1515b4a6e291/page/yyEFE"
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                frameBorder="0"
                allowFullScreen
                className="lookerstudio-embed embed-responsive-item"
              ></iframe>
             
            </div>
          )}
        </TabPane>

        <TabPane tabId="5">
          {activeTab === "5" && (
            <div className="lookerstudio-container embed-responsive embed-responsive-16by9">
              <iframe
                src="https://lookerstudio.google.com/embed/reporting/LUPI_LOOKERSTUDIO_URL"
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                frameBorder="0"
                allowFullScreen
                className="lookerstudio-embed embed-responsive-item"
              ></iframe>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Stats;
