// ItemList.js
import React from "react";
import { ListGroupItem, FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

function ItemList({
  items,
  onToggleStatus,
  onDelete,
  onEdit,
  renderAdditionalContent, // Optional render prop for any additional content
}) {
  return (
    <div className="list-group mt-4 list">
      <h4>My Items List</h4>
      <hr />
      <div style={{ display: "flex", flexDirection: "column", marginTop: "2vh" }}>
        {items.map((item) => (
          <div key={item.id} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            {/* Display the logo */}
            <div style={{ marginRight: "10px" }}>
              {item.LogoUrl && item.LogoUrl[0] && (
                <img src={item.LogoUrl[0]} alt="Item Logo" />
              )}
            </div>

            {/* Display the item details */}
            <ListGroupItem
              style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "space-between", padding: 5 }}
              className="list-item item"
            >
              <span style={{ marginLeft: "20px" }} className="font-2">{item.name}</span>

              {/* Display the switch */}
              <span>
                <FormGroup switch inline>
                  <Input
                    type="switch"
                    checked={item.isOn}
                    onChange={() => onToggleStatus(item)}
                  />
                </FormGroup>
              </span>
            </ListGroupItem>

            {/* Display the action buttons */}
            <div style={{ marginLeft: "10px", display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: 0 }}>
              <FontAwesomeIcon
                icon={faTrash}
                className="mr-2 action-icon"
                style={{ cursor: "pointer", height: "25px", marginRight: "10px" }}
                onClick={() => onDelete(item)}
              />
              <FontAwesomeIcon
                icon={faEdit}
                style={{ cursor: "pointer", height: "25px", marginRight: "10px" }}
                className="action-icon"
                onClick={() => onEdit(item)}
              />
            </div>
          </div>
        ))}

        {/* Optional additional content */}
        {renderAdditionalContent && renderAdditionalContent()}
      </div>
    </div>
  );
}

export default ItemList;
