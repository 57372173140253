import React from "react";

function EducationCard({
  degree,
  department,
  description,      // Add description field
  startDate,
  endDate,
  university,
  location,         // Add location field
  status,           // Add status field
  priority,         // Add priority field
  bulletPoints,
}) {
  return (
    <div className="education-card">
      <h4>{degree}</h4>
      <p>{department}</p>
 
      <p>{startDate} - {endDate}</p>
      <p>{university}</p>
      {location && <p>{location}</p>}  {/* Display location */}
      {status && <p>{status}</p>}  {/* Display status */}
      {priority !== undefined && <p><strong>Priority:</strong> {priority}</p>}  {/* Display priority */}
      {/* Render bullet points if available */}
      {bulletPoints && bulletPoints.length > 0 && (
        <ul>
          {bulletPoints.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default EducationCard;
