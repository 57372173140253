import React from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';

function NumberInput({ label, value, onChange, placeholder }) {
  return (
    <FormGroup>
      <Label sm={2}>{label}</Label>
      <Col sm={2}>
        <Input
          type="number"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Col>
    </FormGroup>
  );
}

export default NumberInput;
