import React, { useState, useEffect } from "react";
export const useModalManager = () => {
    const [activeModal, setActiveModal] = useState(null);
  
    const openModal = (modalName) => setActiveModal(modalName);
    const closeModal = () => setActiveModal(null);
  
    const handleSelectItemAndOpenUpdateModal = (item, setSelectedItem) => {
      setSelectedItem(item);
      openModal('UPDATE');
    };
  
    const handleSelectItemAndOpenDeleteModal = (item, setSelectedItem) => {
      setSelectedItem(item);
      openModal('DELETE_CONFIRMATION');
    };
  
    return {
      activeModal,
      openModal,
      closeModal,
      handleSelectItemAndOpenUpdateModal,
      handleSelectItemAndOpenDeleteModal,
    };
  };
  