// src/utils/itemUtils.js

import { fetchDropdowns, deleteItem, toggleItemStatus, fetchItems } from "./firebaseUtils";
import { db } from "../../firebase";

// Function to refresh dropdowns
export const refreshDropdowns = async (
  firestoreCollection,
  setFilters,
  setSelectedFilters
) => {
  const dropdownResult = await fetchDropdowns(firestoreCollection);
  if (dropdownResult) {
    setFilters(dropdownResult.dropdownData);
    setSelectedFilters(dropdownResult.dynamicSelectedFilters);
  }
};

// Function to delete an item
export const handleDeleteItem = (
  selectedItem,
  items,
  setItems,
  setSelectedItem,
  setShowDeleteConfirmation,
  addNotification,
  firestoreCollection
) => {
  deleteItem(
    selectedItem,
    items,
    setItems,
    setSelectedItem,
    setShowDeleteConfirmation,
    addNotification,
    firestoreCollection
  );
};

// Function to toggle item status
export const handleToggleItemStatus = (
  itemId,
  newStatus,
  items,
  setItems,
  addNotification,
  firestoreCollection
) => {
  toggleItemStatus(
    itemId,
    newStatus,
    items,
    setItems,
    addNotification,
    firestoreCollection
  );
};

// Function to refresh the items list
export const refreshItemsList = async (firestoreCollection, setItems, newItemId) => {
  if (newItemId) {
    const newItemDoc = await db.collection(firestoreCollection).doc(newItemId).get();
    if (newItemDoc.exists) {
      const newItem = { ...newItemDoc.data(), id: newItemDoc.id };
      setItems((prevItems) => [...prevItems, newItem]);
    }
  } else {
    const itemsData = await fetchItems(firestoreCollection);
    setItems(itemsData);
  }
};
