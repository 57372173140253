import { fetchItems, fetchDropdowns, cleanupUnsubmittedMedia } from "./firebaseUtils";

export const fetchDataForManager = async (
  firestoreCollection,
  setItems,
  setFilters,
  setSelectedFilters,
  addNotification
) => {
  try {
    const itemsData = await fetchItems(firestoreCollection);
    setItems(itemsData);

    const dropdownResult = await fetchDropdowns(firestoreCollection);
    if (dropdownResult) {
      setFilters(dropdownResult.dropdownData);
      setSelectedFilters(dropdownResult.dynamicSelectedFilters);
    }

    cleanupUnsubmittedMedia(addNotification);
  } catch (error) {
    console.error("Error fetching data:", error);
    addNotification("Error fetching data. Please refresh the page.", "danger");
  }
};
