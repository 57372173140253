export const applyLocalFilters = (items, selectedFilters) => {
    let filteredItems = items;
  
    Object.keys(selectedFilters).forEach((category) => {
      if (selectedFilters[category].length > 0) {
        filteredItems = filteredItems.filter((item) =>
          selectedFilters[category].every(
            (filter) => item[category] && item[category].includes(filter)
          )
        );
      }
    });
  
    return filteredItems;
  };
  