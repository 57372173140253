import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function GenericModal({
  isOpen,
  toggle,
  title,
  children,
  size = "lg",
  backdrop = "static",
  shouldCloseOnSubmit = false,
  onSubmit,

}) {
  useEffect(() => {
    if (isOpen && shouldCloseOnSubmit && onSubmit) {
      onSubmit(); // Trigger the submission action
      toggle();   // Close the modal
    }
  }, [isOpen, shouldCloseOnSubmit, onSubmit, toggle]);

  return (
    <Modal size={size} fade={true} isOpen={isOpen} toggle={toggle} backdrop={backdrop}>
      <ModalHeader className="footer font-2" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody className="modal-container">{children}</ModalBody>
    </Modal>
  );
}

export default GenericModal;
