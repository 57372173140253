import React, {useState} from "react";
import { Input, Label,CarouselItem, Carousel, CarouselIndicators, CarouselControl, Row, Col  } from "reactstrap";
import { generateFileName } from "../utils/mediaUtils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo, faStar } from "@fortawesome/free-solid-svg-icons";

function MediaUpload({
  firestoreCollection,
  label,
  type,
  files,
  setFiles,
  previews,
  setPreviews,
  addNotification,
  setUploadingMedia,
  setUploadingIndex,
  setUploadProgress,
  deleteFromStorage,
  removeFromUnsubmittedMediaUrls,
  storage,
  addToUnsubmittedMediaUrls,
  setImageFiles,
  setVideoFiles,
  setLogoFiles,
}) {
  const handleMediaChange = async (
    e,
    setFilesFunction,
    setPreviewsFunction,
    type
  ) => {
    try {
      if (e.target.files) {
        const filesArray = Array.from(e.target.files);
        setFilesFunction((prevFiles) => [...prevFiles, ...filesArray]);
        setPreviewsFunction((prevURLs) => [
          ...prevURLs,
          ...filesArray.map((file) => URL.createObjectURL(file)),
        ]);

        for (let i = 0; i < filesArray.length; i++) {
          await handleImmediateUpload(filesArray[i], type, i);
          addNotification("Media added successfully", "success");
        }
      }
    } catch (error) {
      console.error("Error during media change:", error);
      addNotification("Error while adding media. Please try again.", "danger");
    }
  };

  const handleMediaDelete = async (
    index,
    files,
    setFilesFunction,
    setPreviewsFunction
  ) => {
    try {
      if (!window.confirm("Are you sure you want to delete this?")) {
        return;
      }
      const fileToDelete = files[index];
      await deleteFromStorage(fileToDelete.url);
      await removeFromUnsubmittedMediaUrls([fileToDelete.url]);
  
      const updatedFiles = files.filter((_, idx) => idx !== index);
      setFilesFunction(updatedFiles);
  
      // Regenerate the previews
      const updatedPreviews = updatedFiles.map(file => 
        file.url ? file.url : URL.createObjectURL(file)
      );
      setPreviewsFunction(updatedPreviews);
  
      // Adjust the active index if the deleted item was the last one
      if (index === files.length - 1 && activeIndex === index) {
        setActiveIndex(prevIndex => prevIndex - 1);
      }
  
      addNotification("Media deleted successfully", "success");
    } catch (error) {
      console.error("Error during media deletion:", error);
      addNotification(
        "Error while deleting media. Please try again.",
        "danger"
      );
    }
  };
  
  

  const handleImmediateUpload = async (file, type, index) => {
    try {
      setUploadingMedia(type);
      setUploadingIndex(index);
  
      const fileRef = storage.ref(
        generateFileName(file, type, index, firestoreCollection)
      );
      const uploadTask = fileRef.put(file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          throw error; // Throw the error to be caught in the outer try-catch
        }
      );
  
      await uploadTask;
      const downloadURL = await fileRef.getDownloadURL();
  
      // Add the URL to the unsubmittedMediaUrls document
      await addToUnsubmittedMediaUrls(downloadURL);
  
      // Store the access URL in the relevant state
      if (type === "images") {
        setImageFiles((prevFiles) =>
          prevFiles.map((f) => (f === file ? { ...f, url: downloadURL } : f))
        );
      } else if (type === "videos") {
        setVideoFiles((prevFiles) =>
          prevFiles.map((f) => (f === file ? { ...f, url: downloadURL } : f))
        );
      } else if (type === "logos") {
        setLogoFiles((prevFiles) =>
          prevFiles.map((f) => (f === file ? { ...f, url: downloadURL } : f))
        );
      }
  
      setUploadingMedia(null);
      setUploadingIndex(null);
    } catch (error) {
      console.error("Error during immediate upload:", error);
      addNotification("Error during media upload. Please try again.", "danger");
    }
  };
  

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === previews.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? previews.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = previews.map((src, index) => (
    <CarouselItem
    onExiting={() => setAnimating(true)}
    onExited={() => setAnimating(false)}
    key={index}
  >
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '10px' }}>
      
      {type === "videos" ? (
        <video
          src={src}
          alt="Video Preview"
          style={{ width: '100%', height: 'auto', maxWidth: '100%', maxHeight: '50vh', objectFit: 'contain' }}
          controls
        />
      ) : (
        <img
          src={src}
          alt="Preview"
          style={{ width: '100%', height: 'auto', maxWidth: '100%', maxHeight: '50vh', objectFit: 'contain' }}
        />
      )}
  
      <button
        type="button"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "red",
          color: "white",
          border: "none",
          cursor: "pointer",
          borderRadius: '50%',
          padding: '10px',
          width: '30px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleMediaDelete(index, files, setFiles, setPreviews)}
      >
        X
      </button>
    </div>
  </CarouselItem>
  
  ));
  
  

  let icon;
  switch (type) {
    case "images":
      icon = faImage;
      break;
    case "videos":
      icon = faVideo;
      break;
    case "logos":
      icon = faStar;
      break;
    default:
      icon = faStar;
  }

  return (
<div className="mt-3 d-flex align-items-center">
  <div>
    <Label className="" style={{ marginLeft: 20 }}>{label}</Label>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <label
        style={{
          display: "inline-block",
          width: "90px",
          height: "90px",
          borderRadius: "10%",
          borderWidth: 2,
          borderColor: "#939393",
          border: "solid",
          textAlign: "center",
          lineHeight: "100px",
          cursor: "pointer",
          margin: 20
        }}
      >
        <FontAwesomeIcon className="" icon={icon} size="2x" />
        <Input
          className=""
          type="file"
          name={type}
          id={type}
          multiple
          style={{ display: "none" }}
          onChange={(e) => {
            if (type === "images") {
              handleMediaChange(e, setFiles, setPreviews, type);
            } else if (type === "videos") {
              handleMediaChange(e, setFiles, setPreviews, type);
            } else if (type === "logos") {
              handleMediaChange(e, setFiles, setPreviews, type);
            }
          }}
        />
      </label>
    </div>
  </div>

  <div md={8} style={{ marginLeft: '20px', maxWidth: '100%', maxHeight:'500px'  }}>
  <Carousel
  activeIndex={activeIndex}
  next={next}
  previous={previous}
  style={{maxHeight:'500px'}}
>
  {previews.length > 1 && <CarouselIndicators items={previews} activeIndex={activeIndex} onClickHandler={goToIndex} />}
  {slides}
  {previews.length > 1 && <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />}
  {previews.length > 1 && <CarouselControl direction="next" directionText="Next" onClickHandler={next} />}
</Carousel>
  </div>
</div>


  );
}

export default MediaUpload;
