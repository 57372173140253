import React from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';

function RangeInput({ label, value, onChange, min, max }) {
  return (
    <FormGroup>
      <Label sm={2}>{label}</Label>
      <Col sm={8}>
        <Input
          type="range"
          value={value}
          onChange={onChange}
          min={min}
          max={max}
        />
      </Col>
    </FormGroup>
  );
}

export default RangeInput;
