import React from "react";

function ExperienceCard({ role, company, startDate, endDate, city, country, bulletPoints }) {
  return (
    <div className="experience-card">
      <h4>{role}</h4>
      <p>{company}</p>
      <p>{startDate} - {endDate}  |  {city}, {country}</p>
     
      {/* Render bullet points if available */}
      {bulletPoints && (
        <ul>
          {bulletPoints.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ExperienceCard;