import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import "react-quill/dist/quill.snow.css";
import DropdownComponent from "../../forms/DropdownComponent";

import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import NumberInput from "../../forms/NumberImput";
import URLInput from "../../forms/URLInput";

function Add({ refreshList, addNotification }) {
  const [name, setName] = useState("");
  const [issuingBody, setIssuingBody] = useState("");
  const [issuingBodyURL, setIssuingBodyURL] = useState("");
  const [date, setDate] = useState("");
  const [certificationURL, setCertificationURL] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownValues, setDropdownValues] = useState({
    status: [],
  });

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "certifications";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
        if (doc.exists && isMounted) {
          setDropdownValues(doc.data());
        }
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };
    fetchDropdownValues();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        const newItem = {
          name,
          issuingBody,
          issuingBodyURL,
          description,
        date,
        certificationURL,
        isOn: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const newItemRef = await db.collection(firestoreCollection).add(newItem);
      if (refreshList) {
        refreshList();
      }
      addNotification("New Item added successfully", "success");

      setName("");
      setIssuingBody("");
      setIssuingBodyURL("");
      setCertificationURL("");
      setDate("");
      setDescription("");

      return newItemRef.id;
    } catch (error) {
      console.error("Error during form submission:", error);
      addNotification(
        "Error during form submission. Please try again.",
        "danger"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="label tabs" onSubmit={handleSubmit}>
      <Nav tabs justified >
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
          <Col > 
          <TextInput
            label="Certificate Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g Game Developer"
          />
            <URLInput
          label="Certificate URL"
          value={certificationURL}
          onChange={(e) => setCertificationURL(e.target.value)}
          placeholder="https://example.com"
          />
            <TextInput
            label="Issuing Body"
            value={issuingBody}
            onChange={(e) => setIssuingBody(e.target.value)}
            placeholder="e.g RASITGR Games"
          />
          <URLInput
          label="Issuing Body URL"
          value={issuingBodyURL}
          onChange={(e) => setIssuingBodyURL(e.target.value)}
          placeholder="https://example.com"
          />
          <DateInput
            label="Issue Date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
           <RichEditor
            label="Description"
            value={description}
            onChange={setDescription}
          />
           </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-1 primary"
        // color="primary"
        type="submit"
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Add"}
      </Button>
    </Form>
  );
}

export default Add;
