import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import "react-quill/dist/quill.snow.css";

import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import URLInput from "../../forms/URLInput";

function Add({ refreshList, addNotification }) {
  const [title, setTitle] = useState("");
  const [authors, setAuthors] = useState("");
  const [publishingBody, setPublishingBody] = useState("");
  const [date, setDate] = useState("");
  const [doi, setDoi] = useState("");
  const [publicationURL, setPublicationURL] = useState("");
  const [type, setType] = useState("");
  const [abstract, setAbstract] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "publications";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };
    fetchDropdownValues();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const newItem = {
        title,
        authors,
        publishingBody,
        date,
        doi,
        publicationURL,
        type,
        abstract,
        isOn: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const newItemRef = await db.collection(firestoreCollection).add(newItem);
      if (refreshList) {
        refreshList();
      }
      addNotification("New Item added successfully", "success");

      setTitle("");
      setAuthors("");
      setPublishingBody("");
      setDate("");
      setDoi("");
      setPublicationURL("");
      setType("");
      setAbstract("");

      return newItemRef.id;
    } catch (error) {
      console.error("Error during form submission:", error);
      addNotification(
        "Error during form submission. Please try again.",
        "danger"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="label tabs" onSubmit={handleSubmit}>
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
            <Col>
              <TextInput
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="e.g Combat Design in MOBAs"
              />
               <TextInput
                label="Authors"
                value={authors}
                onChange={(e) => setAuthors(e.target.value)}
                placeholder="e.g John Doe, J. Jane Doe..."
              />
              <DateInput
                label="Publish Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
                <TextInput
                label="Publishing Body Name"
                value={publishingBody}
                onChange={(e) => setPublishingBody(e.target.value)}
                placeholder="e.g Game Design Conference"
              />
                <TextInput
                label="DOI (Digital Object Identifier)"
                value={doi}
                onChange={(e) => setDoi(e.target.value)}
                placeholder="e.g https://doi.org/10.1080/10509585.2015.1092083"
              />
              <URLInput
                label="Publication URL"
                value={publicationURL}
                onChange={(e) => setPublicationURL(e.target.value)}
                placeholder="https://example.com/publicationId"
              />
              <TextInput
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                placeholder="e.g Journal, Conference Paper, Book, Article"
              />
              <RichEditor
                label="Abstract"
                value={abstract}
                onChange={setAbstract}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-1 primary"
        // color="primary"
        type="submit"
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Add"}
      </Button>
    </Form>
  );
}

export default Add;
