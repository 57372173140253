import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import "react-quill/dist/quill.snow.css";

import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import URLInput from "../../forms/URLInput";

function Add({ refreshList, addNotification }) {
  const [organization, setOrganization] = useState("");
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [organizationURL, setOrganizationURL] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "volunteerWork";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };
    fetchDropdownValues();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const newItem = {
        organization,
        role,
        date,
        description,
        location,
        organizationURL,
        isOn: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const newItemRef = await db.collection(firestoreCollection).add(newItem);
      if (refreshList) {
        refreshList();
      }
      addNotification("New Item added successfully", "success");

      setOrganization("");
      setRole("");
      setDate("");
      setDescription("");
      setLocation("");
      setOrganizationURL("");

      return newItemRef.id;
    } catch (error) {
      console.error("Error during form submission:", error);
      addNotification(
        "Error during form submission. Please try again.",
        "danger"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="label tabs" onSubmit={handleSubmit}>
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
            <Col>
              <TextInput
                label="Organization Name"
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
                placeholder="e.g Games Ground, AMAZE"
              />
               <TextInput
                label="Role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                placeholder="e.g Game Developer, Stage Management"
              />
              <DateInput
                label="Publish Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
               <RichEditor
                label="Description"
                value={description}
                onChange={setDescription}
              />
                <TextInput
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="e.g Berlin, GER"
              />
                 <URLInput
                label="Organization URL"
                value={organizationURL}
                onChange={(e) => setOrganizationURL(e.target.value)}
                placeholder="https://example.com"
              />            
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-1 primary"
        // color="primary"
        type="submit"
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Add"}
      </Button>
    </Form>
  );
}

export default Add;
