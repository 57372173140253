import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent, Row, Col
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import URLInput from "../../forms/URLInput";

function Update({ itemData, refreshList, addNotification }) {
  const [name, setName] = useState(itemData.name);
  const [certificationURL, setCertificaitonURL] = useState(itemData.certificationURL);
  const [issuingBody, setIssuingBody] = useState(itemData.issuingBody);
  const [issuingBodyURL, setIssuingBodyURL] = useState(itemData.issuingBodyURL);
  const [date, setDate] = useState(itemData.date);
  const [description, setDescription] = useState(itemData.description);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "achievements";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };

    fetchDropdownValues();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedItem = {
        name, 
        certificationURL,
        issuingBody,
        issuingBodyURL,
        date,
        description,
        isOn: itemData.isOn,
        createdAt: itemData.createdAt,
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),   
      };

      await db
        .collection(firestoreCollection)
        .doc(itemData.id)
        .update(updatedItem);

      if (refreshList) {
        refreshList();
      }
      addNotification("Item updated successfully", "success");
    } catch (error) {
      console.error("Error during form update:", error);
      addNotification("Error during form update. Please try again.", "danger");
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="tabs" onSubmit={handleSubmit}>
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
            <Col >
                <TextInput
            label="Achievement Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g Game Developer"
          />
             <URLInput 
          label="Achievement URL"
          value={certificationURL}
          onChange={(e) => setCertificaitonURL(e.target.value)}
          placeholder="https://example.com"
          />
          <TextInput
            label="Issuing Body"
            value={issuingBody}
            onChange={(e) => setIssuingBody(e.target.value)}
            placeholder="e.g RASITGR Games"
          />
          <URLInput 
          label="Issuing Body URL"
          value={issuingBodyURL}
          onChange={(e) => setIssuingBodyURL(e.target.value)}
          placeholder="https://example.com"
          />
          <DateInput
            label="Issue Date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
            <RichEditor
            label="Description"
            value={description}
            onChange={setDescription}
          />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-3 primary"
        // color="primary"
        type="submit"
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Update"}
      </Button>
    </Form>
  );
}

export default Update;
