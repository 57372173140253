// GameDevelopment.js
import React, { useState, useEffect } from "react";
import { Button, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCog } from "@fortawesome/free-solid-svg-icons";
import Add from "./Add.js";
import Update from "./Update.js";
import Settings from "./Settings.js";
import Filters from "../../Filters.js";
import GenericModal from "../../GenericModal.js";
import ItemList from "../../ItemList.js";
import { fetchDataForManager } from "../../utils/dataUtils.js";
import { useModalManager } from "../../utils/modalUtils";
import {
  refreshDropdowns,
  handleDeleteItem,
  handleToggleItemStatus,
  refreshItemsList,
} from "../../utils/itemUtils";
import { applyLocalFilters } from "../../utils/filterUtils";

function GameDevelopment({ addNotification }) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [filters, setFilters] = useState({
    genre: [],
    tools: [],
    role: [],
    expertise: [],
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const firestoreCollection = "gameDevelopment";

  // Use the fetchDataForManager to get items and filters
  useEffect(() => {
    fetchDataForManager(
      firestoreCollection,
      setItems,
      setFilters,
      setSelectedFilters,
      addNotification
    );
  }, [firestoreCollection, addNotification]);

  const {
    activeModal,
    openModal,
    closeModal,
    handleSelectItemAndOpenUpdateModal,
    handleSelectItemAndOpenDeleteModal,
  } = useModalManager();

  const filteredItems = applyLocalFilters(items, selectedFilters);

  return (
    <div>
      <div className="p-3 manager-header">
        <h1 className="title">Edit Game Development Projects</h1>
        <div className="mt-2 button-container">
          <Button className="primary" onClick={() => openModal("ADD")}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
          </Button>
          <Button className="primary m-2" onClick={() => openModal("SETTINGS")}>
            <FontAwesomeIcon icon={faCog} /> Settings
          </Button>
        </div>
        <br />

        <Filters
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          items={items}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />

        <hr className="font-2" />
      </div>

      <ItemList
        items={filteredItems}
        onToggleStatus={(item) =>
          handleToggleItemStatus(
            item.id,
            !item.isOn,
            items,
            setItems,
            addNotification,
            firestoreCollection
          )
        }
        onDelete={(item) => {
          setSelectedItem(item);
          openModal("DELETE_CONFIRMATION");
        }}
        onEdit={(item) => {
          setSelectedItem(item);
          openModal("UPDATE");
        }}
      />

      {/* Add Modal */}
      <GenericModal
        isOpen={activeModal === "ADD"}
        toggle={closeModal}
        title="Add"
      >
        <Add
          addNotification={addNotification}
          refreshList={() => refreshItemsList(firestoreCollection, setItems)}
        />
      </GenericModal>

      {/* Update Modal */}
      <GenericModal
        isOpen={activeModal === "UPDATE"}
        toggle={closeModal}
        title="Update"
      >
        {selectedItem && (
          <Update
            addNotification={addNotification}
            refreshList={() => refreshItemsList(firestoreCollection, setItems)}
            itemData={selectedItem}
            onUpdateSuccess={closeModal}
            firestoreCollection={firestoreCollection}
          />
        )}
      </GenericModal>

      {/* Delete Confirmation Modal */}
      <GenericModal
        isOpen={activeModal === "DELETE_CONFIRMATION"}
        toggle={closeModal}
        title="Confirm Deletion"
      >
        <div>
          Are you sure you want to delete this item?
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                handleDeleteItem(
                  selectedItem,
                  items,
                  setItems,
                  setSelectedItem,
                  setShowDeleteConfirmation,
                  addNotification,
                  firestoreCollection
                );
                closeModal();
              }}
            >
              Delete
            </Button>
            <Button color="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </GenericModal>

      {/* Settings Modal */}
      <GenericModal
        isOpen={activeModal === "SETTINGS"}
        toggle={closeModal}
        title="Settings"
      >
        <Settings
          refreshDropdowns={() =>
            refreshDropdowns(
              firestoreCollection,
              setFilters,
              setSelectedFilters
            )
          }
          addNotification={addNotification}
          firestoreCollection={firestoreCollection}
        />
      </GenericModal>
    </div>
  );
}

export default GameDevelopment;
