import React, { useState, useEffect } from "react";
import { FormGroup, Input, Label, ListGroup, ListGroupItem } from "reactstrap";
import { db } from "../firebase";

function VisibilityManager({addNotification, firestoreCollection}) {
  const [visibilities, setVisibilities] = useState({});

  useEffect(() => {
    console.log(firestoreCollection)
    const fetchVisibilities = async () => {
      try {
        const doc = await db.collection("visibilities").doc(firestoreCollection).get();
        if (doc.exists) {
          setVisibilities(doc.data());
        }
      } catch (error) {
        console.error("Error fetching visibilities:", error);
        addNotification("Error fetching field visibilities.", "danger");
      }
    };

    fetchVisibilities();
  }, [addNotification]);

  const handleToggleVisibility = async (field) => {
    try {
      const updatedValue = !visibilities[field];
      await db.collection("visibilities").doc(firestoreCollection).update({
        [field]: updatedValue,
        
      });
      setVisibilities((prev) => ({
        ...prev,
        [field]: updatedValue,
      }));
      addNotification("New state is applied successfully. Changes are immediately affecting the public app.","success");
    } catch (error) {
      console.error("Error updating visibility:", error);
      addNotification("Error updating field visibility.", "danger");
    }
  };

  return (
    <div>
     
      <ListGroup  flush>
        {Object.entries(visibilities)
          .sort((a, b) => a[0].localeCompare(b[0])) // Sort alphabetically based on the field name
          .map(([field, isVisible]) => (
          <ListGroupItem key={field} className="modal-container d-flex justify-content-between align-items-center font-2">
            <Label className="mb-0">{field.charAt(0).toUpperCase() + field.slice(1)}</Label>
            <FormGroup switch inline>
              <Input
                type="switch"
                checked={isVisible}
                onChange={() => handleToggleVisibility(field)}
              />
            </FormGroup>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
}

export default VisibilityManager;
