import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import "react-quill/dist/quill.snow.css";
import DropdownComponent from "../../forms/DropdownComponent";

import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import NumberInput from "../../forms/NumberImput";

function Add({ refreshList, addNotification }) {
  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [priority, setPriority] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownValues, setDropdownValues] = useState({
    status: [],
  });

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "experience";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
        if (doc.exists && isMounted) {
          setDropdownValues(doc.data());
        }
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };
    fetchDropdownValues();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        const newItem = {
          role,
          company,
          type,
        description,
        startDate,
        endDate,
        location,
        status: selectedStatus,
        priority,
        isOn: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const newItemRef = await db.collection(firestoreCollection).add(newItem);
      if (refreshList) {
        refreshList();
      }
      addNotification("New Item added successfully", "success");
      setCompany("");
      setRole("");
      setType("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setStartDate("");
      setEndDate("");
      setLocation("");
      return newItemRef.id;
    } catch (error) {
      console.error("Error during form submission:", error);
      addNotification(
        "Error during form submission. Please try again.",
        "danger"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="label tabs" onSubmit={handleSubmit}>
      <Nav tabs justified >
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
          <Col > 
          <TextInput
            label="Role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            placeholder="e.g Game Developer"
          />
            <TextInput
            label="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            placeholder="e.g RASITGR Games"
          />
          <DateInput
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
           <DateInput
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
           <TextInput
            label="Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            placeholder="e.g Remote, On Site"
          />
           <TextInput
            label="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="e.g Berlin, GER"
          />
           <NumberInput
             label="Priority"
             value={priority}
             onChange={(e) => setPriority(e.target.value)}
             placeholder="Priority"
          />
           <RichEditor
            label="Description"
            value={description}
            onChange={setDescription}
          />
           </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-1 primary"
        // color="primary"
        type="submit"
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Add"}
      </Button>
    </Form>
  );
}

export default Add;
