import React from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';

function PhoneInput({ label, value, onChange, placeholder }) {
  return (
    <FormGroup>
      <Label sm={2}>{label}</Label>
      <Col sm={8}>
        <Input
          type="tel"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Col>
    </FormGroup>
  );
}

export default PhoneInput;
