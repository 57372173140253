import React from "react";
import { FormGroup, Label, Col, Input } from "reactstrap";

function BooleanSwitchInput({ label, value, onChange }) {
  return (
    <FormGroup>
      <Label sm={2}>{label}</Label>
      <Col sm={8}>
        <Input
          type="switch"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      </Col>
    </FormGroup>
  );
}

export default BooleanSwitchInput;
