import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent, Row, Col
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import URLInput from "../../forms/URLInput";

function Update({ itemData, refreshList, addNotification }) {
  const [title, setTitle] = useState(itemData.title);
  const [authors, setAuthors] = useState(itemData.authors);
  const [publishingBody, setPublishingBody] = useState(itemData.publishingBody);
  const [date, setDate] = useState(itemData.date);
  const [doi, setDoi] = useState(itemData.doi);
  const [publicationURL, setPublicationURL] = useState(itemData.publicationURL);
  const [type, setType] = useState(itemData.type);
  const [abstract, setAbstract] = useState(itemData.abstract);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "publications";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };

    fetchDropdownValues();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedItem = {
        title,
        authors,
        publishingBody,
        date,
        doi,
        publicationURL,
        type,
        abstract,
        isOn: itemData.isOn,
        createdAt: itemData.createdAt,
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),   
      };

      await db
        .collection(firestoreCollection)
        .doc(itemData.id)
        .update(updatedItem);

      if (refreshList) {
        refreshList();
      }
      addNotification("Item updated successfully", "success");
    } catch (error) {
      console.error("Error during form update:", error);
      addNotification("Error during form update. Please try again.", "danger");
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="tabs" onSubmit={handleSubmit}>
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
            <Col >
            <TextInput
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="e.g Combat Design in MOBAs"
              />
               <TextInput
                label="Authors"
                value={authors}
                onChange={(e) => setAuthors(e.target.value)}
                placeholder="e.g John Doe, J. Jane Doe..."
              />
              <DateInput
                label="Publish Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
                <TextInput
                label="Publishing Body Name"
                value={publishingBody}
                onChange={(e) => setPublishingBody(e.target.value)}
                placeholder="e.g Game Design Conference"
              />
                <TextInput
                label="DOI (Digital Object Identifier)"
                value={doi}
                onChange={(e) => setDoi(e.target.value)}
                placeholder="e.g https://doi.org/10.1080/10509585.2015.1092083"
              />
              <URLInput
                label="Publication URL"
                value={publicationURL}
                onChange={(e) => setPublicationURL(e.target.value)}
                placeholder="https://example.com/publicationId"
              />
              <TextInput
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                placeholder="e.g Journal, Conference Paper, Book, Article"
              />
              <RichEditor
                label="Abstract"
                value={abstract}
                onChange={setAbstract}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-3 primary"
        // color="primary"
        type="submit"
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Update"}
      </Button>
    </Form>
  );
}

export default Update;
